<template>
  <main id="site-wrapper">
    <section class="section">
      <div class="container-fluid">
        <div class="section-header">
          <h1>Notifications</h1>
          <div class="section-header-action">
            <span
              v-if="isUnreadNotifications"
              class="btn btn-secondary"
              @click="markAllRead"
            >
              <i class="fas fa-bell-slash mr-2" /> Mark all as read
            </span>
          </div>
        </div>
        <div
          v-for="notification in notifications"
          :key="notification.id"
          class="row row-has-panel"
        >
          <notification-card
            :notification="notification"
          />
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import {userStore} from '@/store/__STORE_user';

import NotificationCard from '@/components/Styling/NotificationCard.vue';
export default {
  name: 'Notifications',
  components: {
    NotificationCard,
  },
  computed: {
    notifications() {
      return userStore.notifications;
    },
    isUnreadNotifications() {
      return this.notifications.some( (element) => {
        return element.read === false;
      });
    },
  },
  mounted() {
    userStore.getNotifications();
  },
  methods: {
    markAllRead() {
      this.notifications.forEach((element) => {
        userStore.markNotificationRead(element.id);
      });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
