<template>
  <div class="col-lg-6">
    <div class="section-panel">
      <div class="section-panel-header">
        <div class="section-panel-header-title">
          <div
            class="icon"
            :class="{'unread': !notification.read}"
          >
            <i class="far fa-bell fa-2x" />
          </div>
          <template v-if="notification.read">
            <div class="content">
              <h3>Read Notification</h3>
            </div>
          </template>
          <template v-else>
            <div class="content">
              <h3>Unread Notification</h3>
            </div>
          </template>
          <span
            v-if="!notification.read"
            class="btn btn-link ml-2"
            @click="markAsRead"
          >
            Mark as read <i class="fas fa-bell-slash ml-2" />
          </span>
        </div>
      </div>
      <div class="section-panel-body">
        <p>{{ notification.message }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {userStore} from '@/store/__STORE_user';
export default {
  name: 'NotificationCard',
  props: {
    notification: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  methods: {
    markAsRead() {
      userStore.markNotificationRead(this.notification.id);
    },
  },
};
</script>

<style lang="scss" scoped>
  .unread {
    color: var(--primary-color) !important;
  }
</style>
